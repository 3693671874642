html {
  @import url('"https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@200&display=swap"');
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  font-family: 'JetBrains Mono', monospace;
  background-color: #dfdbd8;
}

/* https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed */
.grecaptcha-badge {
  display: none !important;
}
