.characters-container {
    width: 50%;
}

.characters {
    padding-left: 50px;
    padding: 15px;
    margin: 5px;
    color: white;
    background-color:#80736a;
}